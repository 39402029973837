<template>
    <div class="box">
        <div v-if="type==1" class="nav_box">
            <div @click="modalBtn(0)" class="one_box">
                <div class="pic_box"><img src="../assets/images/pen2.png" alt=""></div>
                <div class="text" style="color:#035331">合作 <br /> 咨询</div>
            </div>
            <div @click="modalBtn(1)" class="two_box">
                <div>
                    <div class="pic_box"><img src="../assets/images/ly.png" alt=""></div>
                    <div class="text_style">在线留言</div>
                </div>
            </div>
            <div @click="modalBtn(2)" class="two_box">
                <div>

                    <div class="pic_box"><img src="../assets/images/zx.png" alt=""></div>
                    <div class="text_style">在线咨询</div>
                </div>
            </div>
            <div @click="modalBtn(3)" class="two_box">
                <div>
                    <div class="pic_box"><img src="../assets/images/scan.png" alt=""></div>
                    <div class="text_style">扫码关注</div>
                </div>
            </div>

            <!-- 在线留言弹框 -->
            <div class="frame_da_box" v-if="leavingModal">
                <div class="frame_box">
                    <div class="frame_header">
                        <div>在线留言</div>
                        <div @click="closeModal" class="gang">—</div>
                    </div>
                    <div class="frame_content">
                        <div class="input_box">
                            <div class="title">您的姓名：</div>
                            <div><input class="inp" v-model="leavingData.name" placeholder="请输入姓名" type="text"></div>
                        </div>
                        <div class="input_box">
                            <div class="title">您的电话：</div>
                            <div><input class="inp" v-model="leavingData.phone" placeholder="请输入电话号码" type="text"></div>
                        </div>
                        <div class="textarea_box">
                            <div class="title">您的留言：</div>
                            <div><textarea v-model="leavingData.message" placeholder="请输入您的留言" class="textar_style" cols="30" rows="10"></textarea></div>
                        </div>
                        <div class="bottom_btn">
                            <div @click="SubmitBtn" class="add_button">提交</div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 扫码关注弹框 -->
            <div class="scan_da_box" v-if="ScanModal">
                <div class="scan_box">
                    <div class="code_box"><img :src="info.web_official_code" alt=""></div>
                    <div class="scan_text">扫码关注公众号</div>
                </div>
            </div>

        </div>
        <div v-if="type==5" class="nav_box">
            <div @click="signup" class="one_box_join">
                <div class="pic_box"><img src="../assets/images/pen.png" alt=""></div>
                <div class="text">点击 <br /> 报名</div>
            </div>
        </div>
    </div>
</template>

<script>
    import {indexonline,configshow} from '@/api/index.js'
    export default {
        props:{
            type:{
                type: Number,
                default: 1
            },
            info:{
              type:Object
            }
        },
        data() {
            return {
                leavingModal:false,
                ScanModal:false,
                leavingData:{
                    name:'',
                    phone:'',
                    message:''
                }
            }
        },
        created(){
        },
        methods: {
            modalBtn(e){
                switch (e) {
                    case 0:
                        this.$emit('openpop',1)
                        break;
                    case 1:
                        this.ScanModal = false
                        if (this.leavingModal) {
                            this.closeModal()
                        }else{
                            this.leavingModal = true
                        }
                        break;
                    case 2:
                        let qq = "2047918167";
                        window.open("http://wpa.qq.com/msgrd?v=3&uin="+qq+"&site=qq&menu=yes")
                        break;
                    case 3:
                        this.closeModal()
                        this.ScanModal = !this.ScanModal
                        break;
                }
            },
            configshow(){
                configshow().then(res=>{
                    this.info = res.data
                })
            },
            signup(){
                this.$emit('openpop',2)
            },
            SubmitBtn(){
                indexonline(this.leavingData).then(res=>{
                    this.$Message.success(res.message)
                    this.closeModal()
                }).catch(err=>{
                    this.$Message.error(err.message)
                })
            },
            closeModal(){
                this.leavingData = {
                    name:'',
                    phone:'',
                    message:''
                }
                this.leavingModal = false
            }
        },
    }
</script>

<style scoped>
    .box{
        position: fixed;
        z-index: 9999999;
        /* right: -60px; */
        right: 10px;
        top: 50%;
        transition: .3s all;
        margin-top: -178px;
    }
    .box:hover{
        right: 0px;
        padding-right: 10px;
        transition: .3s all;
    }
    .nav_box{
        position: relative;
    }
    .one_box{
        cursor: pointer;
        text-align: center;
        width: 70px;
        padding: 20px 0px;
        border-radius: 5px;
      /*background: rgba(2, 83, 49, .8);*/
      background: #f6b561;
      color:#035331;
    }
    .one_box_join{
        cursor: pointer;
        text-align: center;
        width: 70px;
        padding: 20px 0px;
        border-radius: 5px;
        background: rgba(169, 24, 0, 1);
    }
    .two_box{
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        text-align: center;
        width: 70px;
        height: 70px;
        /* padding: 20px 5px; */
        border-radius: 5px;
        background: rgba(255, 255, 255, .8);
    }
    .text{
        color: #fff;
        margin-top: 5px;
    }
    .text_style{
        color: #fff;
        margin-top: 5px;
        font-size: 12px;
        color: #000;
    }
    .pic_box{
        width:30px;
        height:30px;
        margin: 0 auto;
    }
    .pic_box img{
        width: 100%;
        height: 100%;
    }
    .frame_da_box{
        position: absolute;
        bottom: 0;
        left: -320px;
        width: 320px;
        display: flex;
        justify-content: center;
    }
    .frame_box{
        width: 300px;
        border-radius: 5px;
        overflow: hidden;
    }
    .frame_header{
        display: flex;
        padding: 10px 15px;
        align-items: center;
        font-size: 14px;
        justify-content: space-between;
        color: #fff;
        background: #35755A;
    }
    .frame_content{
        background: #FFF;
    }
    .input_box{
        padding-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .textarea_box{
        padding-top: 10px;
        display: flex;
        justify-content: center;
    }
    .title{
        color: #999;
        font-size: 12px;
    }
    .gang{
        cursor: pointer;
        font-weight: 600;
    }
    .inp{
        width: 190px;
        border: none;
        color: #999;
        background: #F7F7F7;
        padding: 5px;
        outline: none;
    }
    .textar_style{
        width: 190px;
        min-width: 190px;
        max-width: 190px;
        min-height: 70px;
        max-height: 70px;
        border: none;
        color: #999;
        background: #F7F7F7;
        padding: 5px;
        outline: none;
    }
    .bottom_btn{
        display: flex;
        justify-content: flex-end;
        padding: 10px 20px;
    }
    .add_button{
        cursor: pointer;
        text-align: center;
        width: 50px;
        color: #FFF;
        padding: 4px 0;
        border-radius: 5px;
        background: #35755A;
        font-size: 12px;
    }
    .scan_da_box{
        position: absolute;
        bottom: 0;
        left: -160px;
        width: 160px;
        display: flex;
        justify-content: center;
    }
    .scan_box{
        width: 140px;
        background: #FFF;
        border-radius: 5px;
        border: 1px solid #35755A;
        box-sizing: border-box;
        overflow: hidden;
    }
    .code_box{
        /* padding: 20px 0; */
        width: 100px;
        height: 100px;
        margin: 10px auto;
    }
    .code_box img{
        width: 100%;
        height: 100%;
    }
    .scan_text{
        background: #35755A;
        color: #FFF;
        text-align: center;
        padding: 5px 0;
    }


    input::-webkit-input-placeholder {
        color: #999;
        font-size: 12px;
    }
    textarea::-webkit-input-placeholder {
        color: #999;
        font-size: 12px;
    }
</style>